import { Component, Vue, Prop } from 'vue-property-decorator';
import { Organization } from '@/models/Organization';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<SteekproefOrganization>({})
export default class SteekproefOrganization extends Vue {
  public $pageTitle = 'Steekproef';

  @Prop()
  protected organization!: Organization;

  protected mounted() {
    //
  }

  protected get items() {
    const items = [];
    for (let index = 0; index <= 10; index += 1) {
      items.push({
        label: index.toString(),
        value: index,
      });
    }

    return items;
  }

  protected update() {
    this.$emit('update', this.organization);
  }

  // Events
  protected resetOrganization() {
    this.$store.dispatch('openDialog', this.dialogOptionsResetOrganization);
  }

  // Getters
  protected get dialogOptionsResetOrganization(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze steekproef wilt resetten?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Resetten',
          color: 'warning',
          action: () => {

          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }
}
