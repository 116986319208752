var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Steekproef")])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "label", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _vm._v("Bureau")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.organizations, function(
                          organization,
                          index
                        ) {
                          return _c(
                            "v-flex",
                            {
                              key: index,
                              staticClass: "list__item",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _vm._v(
                                      " " + _vm._s(organization.name) + " "
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "label",
                                          "item-value": "value",
                                          items: _vm.items
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSteekproef(
                                              organization
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            organization.report_steekproef_interval,
                                          callback: function($$v) {
                                            _vm.$set(
                                              organization,
                                              "report_steekproef_interval",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "organization.report_steekproef_interval"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-flex", { attrs: { xs5: "" } }, [
                                    _vm._v(
                                      "van de 10 dossiers gaan in de bak Versturen zonder toetsen"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }