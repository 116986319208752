import { Component, Vue } from 'vue-property-decorator';
import { Organization } from '@/models/Organization';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Setting } from '@/models/Setting';
import SteekproefOrganization from '@/views/Settings/Steekproef/Organization/Organization.vue';

@Component<Steekproef>({
  components: {
    SteekproefOrganization,
  },
})
export default class Steekproef extends Vue {
  protected organizations: Organization[] = [];

  protected activeOrganization = '';

  // loadings
  protected isLoading = true;

  protected types: any = [
    {
      name: 'Totalen',
      key: 'organization',
    },
    // {
    //   name: 'Type Rapport',
    //   key: 'reportTypes',
    // },
    // {
    //   name: 'Technische Coördinators',
    //   key: 'users',
    // },
  ];

  protected mounted() {
    this.getOrganizations();
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Instellingen/steekproef' },
        ],
      });
  }

  protected getOrganizations() {
    this.isLoading = true;
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
        if (this.organizations.length) {
          this.activeOrganization = this.organizations[0].id || '';
        }

        this.getSettings();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getSettings() {
    let count = this.organizations.length;
    this.organizations.forEach((organization: Organization) => {
      if (! organization || ! organization.id) {
        return;
      }

      new Setting()
        .dmz(organization.id)
        .all()
        .then((settings: Setting[]) => {
          const foundSetting = settings.filter((setting: Setting) => setting.key === 'report_steekproef_interval');

          if (foundSetting[0]) {
            organization.report_steekproef_interval = foundSetting[0].value as string;
          }
          count -= 1;
        })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        })
        .finally(() => {
          if (count === 0) {
            this.isLoading = false;
          }
        });
    });
  }

  protected updateSteekproef(organization: Organization) {
    if (! organization || ! organization.id) {
      return;
    }

    new Setting()
      .dmz(organization.id)
      .update([
        {
          key: 'report_steekproef_interval',
          value: organization.report_steekproef_interval,
        },
      ])
      .then((setting: Setting) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get items() {
    const items = [];
    for (let index = 0; index <= 10; index += 1) {
      items.push({
        label: index.toString(),
        value: index,
      });
    }

    return items;
  }
}
