var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "item__list", attrs: { wrap: "", "pa-3": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "list__item", attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs4: "" } }, [_vm._v("Aantal")]),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "item-text": "label",
                          "item-value": "value",
                          items: _vm.items,
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.organization.report_steekproef_interval,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.organization,
                              "report_steekproef_interval",
                              $$v
                            )
                          },
                          expression: "organization.report_steekproef_interval"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs5: "" } }, [
                    _vm._v(
                      "van de 10 dossiers gaan in de bak Versturen zonder toetsen"
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "", "px-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { "text-xs-right": "" } },
            [
              _c(
                "MiButton",
                {
                  staticClass: "mr-1",
                  attrs: {
                    color: "warning",
                    small: "true",
                    compact: "true",
                    outline: "true"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.resetOrganization()
                    }
                  }
                },
                [_vm._v(" Resetten ")]
              ),
              _c(
                "MiButton",
                {
                  staticClass: "ml-1",
                  attrs: {
                    color: "primary",
                    small: "true",
                    compact: "true",
                    outline: "true"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.update()
                    }
                  }
                },
                [_vm._v(" Opslaan ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }